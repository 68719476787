import { Link } from "react-router-dom";

function ContactFooter() {
  return (
    <section id="contact-us">
      <div className="container pt-5 text-white pb-md-5">
        <br />
        <h4 className="color-blue text-center py-2 mb-3">
          <strong>Fill out the Form to Get in Touch with Our Experts</strong>
        </h4>
        <div className="row mt-1  justify-content-center align-items-center ">
          <div className="col-md-12">
            <h6 className="text-light text-center">
              <Link className="nav-link" to="/contact-us">
                <button className="btn btn-light">Contact Us</button>
              </Link>
            </h6>
            <br />
            <div className="d-flex justify-content-center">
              <Link
                to="https://wa.me/+917042562687"
                target="_blank"
                className=" mx-2 btn text-light fs-3"
              >
                <i className="bi bi-whatsapp"></i>
              </Link>
              <Link
                to="https://www.instagram.com/vyomscode?igsh=Z28xbGlzZndvNTFz"
                target="_blank"
                className=" mx-2 btn text-light fs-3"
              >
                <i className="bi bi-instagram"></i>
              </Link>
              <Link
                to="https://www.linkedin.com/in/vyoms-code-7825902a5?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                className=" mx-2 btn text-light fs-3"
              >
                <i className="bi bi-linkedin"></i>
              </Link>
              <Link
                to="https://www.facebook.com/profile.php?id=61554507912666&mibextid=ZbWKwL"
                target="_blank"
                className=" mx-2 btn text-light fs-3"
              >
                <i className="bi bi-facebook"></i>
              </Link>
            </div>
            <br />
            <p className="text-center">support@vyomscode.com</p>
            <p className="text-center mb-0">
              <Link to="/terms-and-conditions" className=" text-light">
                Terms&Conditions
              </Link>
              &nbsp;&nbsp;&nbsp;
              <Link to="/privacy-policy" className="text-light">
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
        <br />
      </div>
    </section>
  );
}

export default ContactFooter;
