// import React, { useState } from "react"; 
import TriptiProfileImg from "../../../src/assets/profile-tripti.webp";
// import RudraProfileImg from "../../../src/assets/profile-rudra.webp";
import { Link } from "react-router-dom";
import AboutUsImg from "../../../src/assets/Vyomscode-aboutUs.png";

function FAQs() { 


  return (
    <section id="contact" 
    style={{ backgroundImage: "linear-gradient(black,#ffffff00,#070f2b00)" }}
    > 
      <div className="container pt-5 text-white pb-md-5">
        <h2 className="color-blue text-center mb-3">
          <strong>Vyomscode - Software Solutions Company</strong>
        </h2>
        <p className="color-blue text-center mb-3">
          <strong>A team of passionate professionals delivering innovative tech solutions.</strong>
        </p> 
        <div className="row justify-content-center align-items-center py-5">
            <div className="col-md-6">  
                <h3 className="color-blue  mb-3 text-light"> <strong>About Vyomscode</strong> </h3> 
                <p className="lh-lg text-light">
                    Founded in 2022, Vyomscode is a forward-thinking software solutions company dedicated 
                    to driving innovation in the tech industry. From its inception, Vyomscode has worked 
                    with a diverse range of clients, including established corporations, emerging startups, 
                    and entrepreneurial ventures, delivering customized software solutions that cater to their 
                    specific needs.
                    <br />
                    Our talented team thrives on creativity, technical expertise, and a passion
                     for problem-solving, enabling us to deliver exceptional results across a 
                     variety of domains. Whether it's designing sleek, responsive websites, 
                     developing scalable mobile applications, or crafting comprehensive software systems,
                      we turn challenges into opportunities.
                    <br />
                    With a strong focus on collaboration and client-centric development, Vyomscode 
                    continues to build its reputation as a trusted partner for businesses aiming to
                     expand their digital footprint and achieve their objectives in today's competitive landscape.
                </p> 
            </div>
            <div className="col-md-4"> 
                <img src={AboutUsImg} className="w-100" alt="Vyomscode - Software Solutions Company" />
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-md-5"> 
                <div className="card border-light h-100 bg-transparent"> 
                    <div className="card-body">
                        <h3 className="color-blue text-center mb-3 text-light"> <strong>Our Mission</strong> </h3>
                        <p className="lh-lg  text-light">
                            At Vyomscode, our mission is to deliver state-of-the-art 
                            software solutions that seamlessly align with our clients' needs and aspirations. 
                            We are committed to ensuring quality, efficiency, and innovation in every project 
                            we undertake. By harnessing the latest technologies and a customer-centric approach,
                             we aim to empower businesses, streamline processes, and drive measurable success. 
                             Our dedication lies in building long-lasting relationships with clients by consistently 
                             exceeding expectations and delivering unmatched value.
                        </p>
                    </div>
                </div> 
            </div>
            <div className="col-md-5"> 
                <div className="card border-light h-100 bg-transparent"> 
                    <div className="card-body">
                        <h3 className="color-blue text-center mb-3  text-light"> <strong>Our Vision</strong> </h3>
                        <p className="lh-lg  text-light">
                            Our vision is to establish Vyomscode as a global leader in the 
                            software development industry, where innovation meets excellence. 
                            We aspire to create an ecosystem where businesses of all sizes can 
                            leverage the power of technology to transform their ideas into impactful solutions.
                            By fostering a culture of creativity, integrity, and collaboration, 
                            we envision a future where Vyomscode becomes synonymous with reliability, 
                            growth, and cutting-edge technological advancements.
                        </p>
                    </div>
                </div> 
            </div>
        </div>
        <div className="row justify-content-center py-5">
            <div className="col-md-12">
                <h4 className="color-blue text-center mb-3">
                    <strong>Meet Our Team</strong>
                </h4>  
            </div>
            <div className="col-md-8">
                <div className="card border-light h-100 bg-transparent" >
                <div className="row g-0">
                    <div className="col-md-4">
                        <img src={TriptiProfileImg} className="w-100" alt='Tripti Thakur' /> 
                    </div>
                    <div className="col-md-8">
                        <div className="card-body pt-2">
                            <div className="d-md-flex mb-3 justify-content-between"> 
                                <div>
                                    <h5 className="card-title mb-0 text-light text-center">Tripti Thakur</h5>
                                    <p className="card-text text-light text-center">Managing Director</p>
                                </div>
                                <div className="d-md-flex justify-content-end align-items-center">
                                    <div className="d-flex justify-content-center my-md-0 my-3">
                                    <Link
                                    target="_blank"
                                    to='https://wa.me/+917042562687'
                                    className="nav-link text-success fs-5"
                                    >
                                    <i className="bi bi-whatsapp mx-1"></i>
                                    </Link>
                                    <Link
                                    target="_blank"
                                    to="https://www.linkedin.com/in/tripti-thakur-256431219?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                                    className="nav-link text-primary fs-5"
                                    >
                                    <i className="bi bi-linkedin mx-1"></i>
                                    </Link>
                                    <Link
                                    target="_blank"
                                    to="https://www.instagram.com/triptithakur0?igsh=amd2dTZ0b3Ryb3o3"
                                    className="nav-link text-danger fs-5"
                                    >
                                    <i className="bi bi-instagram mx-1"></i>
                                    </Link>
                                    <Link
                                    target="_blank"
                                    to="https://tripti.vyomscode.com/"
                                    className=" btn btn-outline-light py-1 px-2 mx-2 fs-6"
                                    >
                                    Portfolio
                                    </Link>
                                    </div>
                                </div>
                            </div> 
                            <p className=" mb-0 text-light">
                                Hi, I'm Tripti! 👋 A Computer Science BTech graduate passionate 
                                about web development, graphic design, UI/UX design, and social media 
                                content creation. 🖥️🎨 I love tackling challenges, exploring diverse ideas, 
                                and continuously learning new skills. 💡 In my downtime, I refine my 
                                coding and design techniques while mentoring aspiring developers.  
                                My mission is to grow personally and professionally, making a positive 
                                impact along the way. ✨ 
                            </p> 
                        </div>  
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-center py-2 gy-1">
            <div className="col-md-3">
                <div className="card border-light h-100 bg-transparent">
                    {/* <div className="card-head"> 
                        <img src={RudraProfileImg} className="w-100" alt='Rudra Pratap Singh' />
                    </div> */}
                    <div className="card-body text-light">
                        <h5 className="card-title mb-0">Rudra Pratap Singh</h5>
                        <p className="card-text">Operations Manager & Senior Developer</p>
                        <p className="lh-lg mb-0">
                        🚀 A PHP & MySQL expert skilled in JavaScript, Java, database management and web technologies. 
                        Blending technical expertise with creativity and efficiency to build seamless 
                        and scalable digital experiences. Let's turn ideas into reality with 
                        innovation and precision!
                        </p>
                    </div>
                </div>
            </div> 
            <div className="col-md-3">
                <div className="card border-light h-100 bg-transparent">
                    {/* <div className="card-head"> 
                        <img src={TriptiProfileImg} className="w-100" alt='Satyajeet Patra' />
                    </div> */}
                    <div className="card-body text-light">
                        <h5 className="card-title mb-0">Satyajeet Patra</h5>
                        <p className="card-text">Project Associates & Senior Developer</p>
                        <p className="lh-lg mb-0">
                        😊 A Computer Science graduate with expertise in web development, skilled in HTML, 
                        CSS, JavaScript, PHP, MySQL, Angular, and React. Passionate about crafting seamless 
                        user experiences by blending front-end design with back-end functionality.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-center py-2 gy-1">
            <div className="col-md-3"> 
                <div className="card border-light h-100 bg-transparent"> 
                    <div className="card-body text-light">  
                        <h5 className="card-title mb-0">Junior Web Developer Team</h5> 
                        <p className="lh-lg mb-0">
                        🌟Focuses on web design and development, contributing to the front-end and back-end development of websites.<br />
                        🌟Skilled in HTML, CSS, Bootstrap, Tailwind, JavaScript, and PHP. <br />
                        🌟Ideal for candidates with internship experience in web design and development. <br />
                        </p>
                    </div> 
                </div> 
            </div> 
            <div className="col-md-3">
                <div className="card border-light h-100 bg-transparent"> 
                    <div className="card-body text-light">   
                        <h5 className="card-title mb-0">Training & Internship Team</h5> 
                        <p className="lh-lg mb-0">
                        🌟Contribute to web design and development for assigned projects.<br />
                        🌟Build foundational skills in HTML, CSS, Bootstrap, JavaScript, and PHP. <br />
                        🌟Designed for freshers eager to gain hands-on experience. <br />
                        </p>
                    </div> 
                </div> 
            </div> 
            <div className="col-md-3"> 
                <div className="card border-light h-100 bg-transparent"> 
                    <div className="card-body text-light">  
                        <h5 className="card-title mb-0">Training-cum-Internship Program</h5> 
                        <p className="lh-lg mb-0">
                        🌟Designed to help aspiring developers master web design and development.<br />
                        🌟Focuses on providing real-world experience while building a strong foundation for a software development career. 
                        </p>
                    </div> 
                </div>  
            </div>
        </div>
      </div>
    </section>
  );
}

export default FAQs;

