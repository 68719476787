
import { Modal } from 'react-bootstrap';
import { Mail, Phone, Globe, 
 Smartphone, Paintbrush, Database, BarChart, MessageSquare, Search} from 'lucide-react';
 import { useRef } from "react"; 

const ContactModal = ({ show, onClose }: { show: boolean; onClose: () => void }) => {
  
    const formRef = useRef<HTMLFormElement>(null); 
    const services = [
        { value: 'Web Development', label: 'Web Development', icon: Globe },
        { value: 'App Development', label: 'App Development', icon: Smartphone },
        { value: 'Graphic design', label: 'Graphic Design', icon: Paintbrush },
        { value: 'SEO Management', label: 'SEO Management', icon: Search },
        { value: 'Database Administration', label: 'Database Administration', icon: Database },
        { value: 'Digital marketing', label: 'Digital Marketing', icon: BarChart }
      ];

  return (
    <Modal show={show} onHide={onClose} centered size="lg" id="contactformmodal">
      <div className="modal-content border-0"> 
        <Modal.Body className="px-2 py-2 pb-4" > 
          <p className="text-end mb-0"> 
            <button className="btn btn-danger px-2 py-1" onClick={onClose}><i className="bi bi-x-square"></i></button>
          </p>
          <div className='px-md-3'>
            <h2 className="text-center mb-2 text-light fw-bold">Transform Your Vision Into Reality</h2>
            {/* <p className="text-center text-muted mb-4">
              Let VyomsCode craft the perfect software solution tailored to your needs
            </p> */}

            <div className="d-flex flex-wrap justify-content-center gap-3 mb-4">
              <a href="mailto:support@vyomscode.com" className="contact-link text-light">
                <Mail size={16} className="me-2" />
                support@vyomscode.com
              </a>
              <a href="tel:+918726324687" className="contact-link text-light"> 
                <Phone size={16} className="me-2" />
                8726324687
              </a>
            </div>

            {/* <form> */}
            <form
                ref={formRef}
                id="form"
                //  onSubmit={handleSubmit}
                action="https://vyomscode.com/contactform.php"
                method="post"
                onSubmit={(e) => {
                  const textarea = document.querySelector<HTMLTextAreaElement>('textarea[name="msg"]');
                  if (textarea) {
                    const additionalText = "  || Contact Popup";
                    textarea.value += additionalText; // Appending predefined text to the user's message
                  }              
                }}
              >
              <div className="row g-3 mb-3">
                <div className="col-md-12">
                  <div className="input-group">
                    <span className="input-group-text">
                      <MessageSquare size={16} />
                    </span>
                    <input
                      type="text" 
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                </div>
                
                <div className="col-md-12">
                  <div className="input-group">
                    <span className="input-group-text">
                      <Mail size={16} />
                    </span>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="input-group">
                    <span className="input-group-text">
                      <Smartphone size={16} />
                    </span>
                    <input
                      type="number"
                      name="number"
                      className="form-control"
                      placeholder="Phone number"
                      required
                    />
                  </div>
                </div>

              </div>

              <div className="input-group mb-3">
                <span className="input-group-text">
                  <Globe size={16} />
                </span>
                <select className="form-select" 
                      name="msg">
                  {services.map(({ value, label }) => (
                    <option key={value} value={value}>{label}</option>
                  ))}
                </select>
              </div>

              {/* <textarea
                className="form-control mb-3"
                rows={4}
                placeholder="Tell us about your project"
              ></textarea>  */}

              <button type="submit" className="btn btn-warning w-100">
                Let's Get Started
              </button>
            </form>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ContactModal;