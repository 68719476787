import { useRef } from "react";
import {Helmet} from "react-helmet";
import InternshipImgo from "../../assets/InternshipImgone.jpg"
import InternshipImgtw from "../../assets/InternshipImagetwo.jpg"
import { Link } from "react-router-dom";
import { Mail, Phone , CheckCircle, CreditCard, CalendarCheck, FileText, Award, BookOpen } from 'lucide-react';

function PaidInternship(){ 
  const steps = [
    {
      id: 1,
      icon: <FileText className="text-light w-8 h-8" />,
      title: "Register on the Website",
      description: "Visit our website and complete the registration process.",
    },
    {
      id:2,
      icon: <CreditCard className="text-light w-8 h-8" />,
      title: "Pay the Registration Fee",
      description: "Pay ₹499 to confirm your registration.",
    },
    {
      id: 3,
      icon: <CalendarCheck className="text-light w-8 h-8" />,
      title: "Test Scheduling",
      description: "The Vyomscode team will schedule your test and inform you of the details.",
    },
    {
      id: 4,
      icon: <CheckCircle className="text-light w-8 h-8" />,
      title: "Take the Test",
      description: "Attempt the 60-question test to showcase your skills.",
    },
    {
      id: 5,
      icon: <Award className="text-light w-8 h-8" />,
      title: "Score and Discount Notification",
      description: "Based on your performance, the Vyomscode team will calculate your discount and share the details with you.",
    },
    {
      id: 6,
      icon: <BookOpen className="text-light w-8 h-8" />,
      title: "Finalize Enrollment",
      description: "Pay the discounted course fee to book your seat.",
    },
    {
      id: 7,
      icon: <Mail className="text-light w-8 h-8" />,
      title: "Joining Letter",
      description: "Upon enrollment, receive your program joining letter with the start date for your learning program.",
    },
  ];

  return (
    <section
      id="contact"
      style={{ backgroundImage: "linear-gradient(black, #070F2B)" }}
    >
    <Helmet>
      <meta charSet="utf-8" />
      <title>Internships at Vyomscode | Kickstart Your Career</title>
      <meta
        name="description"
        content="Apply for paid internships at Vyomscode and gain real-world experience in web development, app development, digital marketing, and graphic design. Start your career with us!"
      />
      <meta
        name="keywords"
        content="paid internships Vyomscode, internships for web development, app development internship, digital marketing internships, graphic design internship, IT internships India, tech industry internships, learn web development, paid training Vyomscode, internships for freshers, software development internships, hands-on coding experience, digital marketing training, build your career Vyomscode, software internship programs, real-world project internships, internship opportunities Vyomscode, start your career in tech, IT paid internship program, practical experience for students"
      />
    </Helmet>

      <div className="container pt-5 text-white pb-md-5">
        <h2 className="color-blue text-center">
          <strong>Vyomscode Internship & learning  Program</strong>
        </h2>
        <p className="color-blue text-center py-2 mb-3">
          <strong>Empowering Future Developers: Learn, Build, and Succeed with Vyomscode</strong>
        </p>

        <div className="row align-items-center py-5">
          <div className="col-md-6 my-1"> 
            <h4 className="color-blue">
              <strong>Kickstart Your Career in Full Web Development!</strong>
            </h4>
            <p className="color-blue py-2 mb-3 lh-lg">
              <strong>Vyomscode is excited to offer a 3-month paid internship that provides hands-on training in Full Web Development, equipping you with the skills and confidence to succeed in the tech industry. This internship is thoughtfully divided into Frontend Development and Backend Development modules, with additional professional development perks to help you shine in the job market.</strong>
            </p>
          </div>
          <div className="col-md-6 my-1 d-flex justify-content-center">
            <img
              // src="https://img.freepik.com/premium-vector/web-development-isolated-cartoon-concept-developers-customize-optimize-pages-office_9209-6631.jpg?w=740"
              src={InternshipImgo}
              alt="Vyomscode Work path"
              className="path-img w-50 rounded"
            />
          </div>  
        </div>
        <div className="row align-items-center justify-content-center py-3">
          <div className="col-md-6 my-1 d-flex justify-content-center">
            <img
              src={InternshipImgtw}
              alt="Vyomscode Work path"
              className="path-img rounded"
            />
          </div>  
          <div className="col-md-6 my-1">
          <h4 className="color-blue lh-lg">
              <strong>Why Choose Vyomscode's Internship?</strong>
            </h4>
            <p className="color-blue py-2 mb-3"> 
              <strong>Comprehensive Curriculum:</strong> Learn both frontend and backend development with real-world projects.  <br /><br />
              <strong>Hands-On Experience:</strong> Work on 15 live projects to build your practical knowledge. <br /><br />
              <strong>Professional Development:</strong> Receive guidance on social media branding, resume creation, and job applications. <br /><br />
              <strong>Mock Interviews:</strong> Practice with 2 mock interviews to prepare for real-world scenarios. <br /><br />
              <strong>Certification:</strong> Get a Vyomscode Internship Certificate upon successful completion. <br /><br />
            </p> 
          </div> 
        </div>
        <div className="row justify-content-center py-3">
          <div className="col-12">
            <h3 className="color-blue text-center">
              <strong>Internship Breakdown</strong>
            </h3>
          </div>
          <div className="col-md-4 my-1">
            <div className="card card-body h-100 bg-transparent border-light"> 
              <h4 className="text-info text-center">
                <strong>Frontend Development </strong>
              </h4> 
              <p className="color-blue mb-0 lh-lg"> 
                Master the art of building stunning and responsive websites using: <br />
                <strong className="text-info">HTML:</strong> Structure the web pages effectively.  <br /> 
                <strong className="text-info">CSS:</strong> Style your web pages with creativity. <br /> 
                <strong className="text-info">JavaScript:</strong> Add dynamic and interactive features. <br /> 
                <strong className="text-info">Bootstrap:</strong> Build responsive designs quickly. <br /> 
                <strong className="text-info">Tailwind CSS:</strong> Simplify styling with utility-first CSS. <br /> 
                <strong className="text-info">Projects:</strong> Gain practical experience with 10 hands-on projects that showcase your frontend expertise. <br />
              </p> 
            </div>
          </div>
          <div className="col-md-4 my-1">
            <div className="card card-body h-100 bg-transparent border-light"> 
              <h4 className="text-info text-center">
                <strong>Backend Development </strong>
              </h4> 
              <p className="color-blue mb-0 lh-lg"> 
                Delve into the core of web functionality by mastering: <br />
                <strong className="text-info">MySQL:</strong> Learn to design and manage databases.<br /> 
                <strong className="text-info">Database Design:</strong> Understand schemas and relationships.<br /> 
                <strong className="text-info">phpMyAdmin:</strong> Efficiently manage MySQL databases.<br /> 
                <strong className="text-info">PHP:</strong> Build robust and scalable backend systems.<br /> 
                <strong className="text-info">Projects:</strong> Complete 5 backend-focused projects to demonstrate your backend skills. <br /> 
              </p> 
            </div>
          </div>
        </div> 
        <div className="row align-items-center justify-content-center py-3"> 
          <div className="col-md-7 my-1 lh-lg">
            <h4 className="text-info">
              <strong>Additional Benefits</strong>
            </h4>
            <p className="color-blue py-2 mb-3 lh-lg"> 
              Vyomscode goes beyond technical training to prepare you for a successful career: <br /> 

              <strong className="text-info">Social Media Profile Creation:</strong>  Build a standout professional LinkedIn profile. <br /> 
              <strong className="text-info">Web Portfolio Development:</strong>  Showcase your work and skills with a personal portfolio website. <br /> 
              <strong className="text-info">Resume Assistance:</strong>  Craft a compelling resume to impress employers. <br /> 
              <strong className="text-info">Job Application Guidance:</strong>  Get tips and support in applying for tech roles. <br /> 
              <strong className="text-info">Mock Interviews:</strong>  Practice with 2 realistic mock interviews to boost your confidence. <br /> 
            </p> 
          </div> 
          <div className="col-md-5 my-1 lh-lg">
            <h4 className="text-info">
              <strong>Program Details</strong>
            </h4>
            <p className="color-blue py-2 mb-3 lh-lg"> 
              <strong className="text-info">Duration:</strong>  3 months (Full-time available)<br /> 
              <strong className="text-info">Certificate:</strong>  Vyomscode Internship Certificate upon successful completion.<br /> 
              <strong className="text-info">Who Can Apply:</strong>  Freshers, students, and anyone passionate about web development.<br /> 
              <strong className="text-info">Fee:</strong>  Paid internship with a minimal fee for lifetime value.<br /> 
            </p> 
          </div> 
        </div>


        <div className="row my-3 justify-content-center align-items-center ">
          <div className="col-md-12">
            <h5 className="color-blue"> 
              To learn more about our internship program, 
              feel free to connect with us. 
              We're happy to assist you with any queries!  
            </h5> 
            <div className="d-flex flex-wrap  gap-3 mb-4">
                <a href="mailto:support@vyomscode.com" className="contact-link text-light">
                  <Mail size={16} className="me-2" />
                  support@vyomscode.com
                </a> 
                <a href="tel:+918726324687" className="contact-link text-light"> 
                  <Phone size={16} className="me-2" />
                  8726324687
                </a>
            </div> 
          </div> 
          <div className="col-md-12 mt-4">
            <h4 className="color-blue mb-3">
              <strong>How to Apply?</strong>
            </h4>
            <div className="row gy-4">
              {steps.map((step, index) => (
                <div key={index} className="col-6 d-flex align-items-start">
                  <p className="btn btn-sm btn-info py-0 px-2 rounded-circle me-1">{step.id}</p>
                  <div className="me-3 text-light">{step.icon}</div>
                  <div>
                    <h6 className="fw-semibold ">{step.title}</h6>
                    <p className="mb-0 text-light">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <p className="color-blue py-2 mb-3 lh-lg"> 
              Interested in joining our internship program? Fill out the form to secure your spot and take the first step toward an exciting career in web development.

              Let Vyomscode be your gateway to a bright future in tech!
            </p> 
            
            <Link to="https://vyomscode.com/internship.html"  target="_blank"> <button className="btn btn-lg btn-warning my-3 w-25">Enroll Now</button> </Link>
          </div>  
        </div>
      </div>
    </section>
  );
}

export default PaidInternship
