import { Link } from "react-router-dom";
import logo from "../../assets/logoV.png";

function Header() {
  return (
    <nav className="navbar navbar-expand-lg py-1 px-md-4 py-0 sticky-top">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="logo" width="65" height="60" />
        </Link>
        <div className="large-hide-call-btn" style={{ marginRight: "-60px" }}>
          <Link className="nav-link" target="_blank" to="tel:7042562687">
            <button className="btn btn-light rounded-pill">
              Call&nbsp;Us
            </button>
          </Link>
        </div>
        <button
          className="navbar-toggler text-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="bi bi-list text-light"></i>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0">
            {/* <li className="nav-item">
              <Link className="nav-link" aria-current="page" to="/#about-us">
                About&nbsp;Us
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/aboutUs">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/work">
                Work
              </Link>
            </li>
            <li className="nav-item dropdown custom-nav-link">
              <span
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </span>
              <ul className="dropdown-menu">
                <li className="custom-dropdown-item">
                  <Link
                    className="dropdown-item"
                    to="/services/website-development"
                  >
                    Web Development
                  </Link>
                </li>
                <li className="custom-dropdown-item">
                  <Link
                    className="dropdown-item"
                    to="/services/app-development"
                  >
                    App Development
                  </Link>
                </li>
                <li className="custom-dropdown-item">
                  <Link
                    className="dropdown-item"
                    to="/services/graphic-designing"
                  >
                    Graphic Designing
                  </Link>
                </li>
                <li className="custom-dropdown-item">
                  <Link
                    className="dropdown-item"
                    to="/services/digital-marketing"
                  >
                    Digital Marketing
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/pricing">
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                target="_blank"
                to="https://blog.vyomscode.com/"
              >
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                target="_blank"
                to="/internship"
              >
                Internship
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link" 
                to="/career"
              >
                Career
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact-us">
                Contact&nbsp;Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="small-hide-call-btn">
          <Link className="nav-link" target="_blank" to="tel:8726324687">
            <button className="btn btn-light rounded-pill">
              Call&nbsp;Us
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Header;
