import { useRef } from "react";
import {Helmet} from "react-helmet";
import CareerImg from "../../assets/Carrerimg.png"

function Careerpage() {
  const formRef = useRef<HTMLFormElement>(null); 
  const careerFormDivRef = useRef<HTMLDivElement>(null)

  return (
    <section
      id="contact"
      style={{ backgroundImage: "linear-gradient(black, #070F2B)" }}
    >
    <Helmet>
      <meta charSet="utf-8" />
      <title>Careers at Vyomscode | Join Our Team</title>
      <meta
        name="description"
        content="Explore exciting career opportunities at Vyomscode. Join our team of professionals in web development, app development, digital marketing, and design. Build your future with us."
      />
      <meta
        name="keywords"
        content="careers at Vyomscode, job openings Vyomscode, work at Vyomscode, web development careers, app development jobs, digital marketing opportunities, graphic design jobs, software development careers, IT jobs Vyomscode, tech industry careers, join Vyomscode team, software engineering jobs, career growth in IT, apply Vyomscode jobs, latest IT jobs, web solutions company jobs, coding and programming careers, tech job openings, digital marketing expert jobs, career in software development"
      />
    </Helmet>

      <div className="container pt-5 text-white pb-md-5">
        <h2 className="color-blue text-center">
          <strong>Join the Vyomscode Family: Shape the Future of Technology with Us</strong>
        </h2>
        <p className="color-blue text-center mb-1">
          <strong>Innovate, Collaborate, and Grow in a Dynamic Environment That Empowers Your Career</strong>
        </p>  
        <p className="color-blue text-center py-2 mb-3">
          {/* <strong>Opportunities at Our Workplace</strong>   */}
          We're on the lookout for passionate individuals to join our team at Vyomscode! Whether you're an experienced professional or a fresher with internship experience, we have exciting roles tailored to kickstart or elevate your career in the tech and creative domains.
        </p> 

        <div className="row"> 
          <div className="col-12 d-flex justify-content-center mb-3">
            <img
              src={CareerImg}
              alt="Vyomscode Work path"
              className="path-img w-50" 
            />
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>Frontend Developer</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Build responsive and user-friendly web interfaces.
                  Work with HTML, CSS, JavaScript, and frameworks like React or Angular.
                  Optimize designs for performance and ensure cross-browser compatibility. <br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in frontend development or relevant internships.
                  Strong knowledge of web design principles and frameworks.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>Backend Developer</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Develop server-side logic, APIs, and database management systems.
                  Work with technologies like Node.js, Python, or Java.
                  Ensure security, performance, and scalability of applications. <br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in backend development or relevant internships.
                  Proficiency in database technologies like MySQL or MongoDB.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>React Developer</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Build dynamic and scalable web applications using React.js.
                  Optimize components for maximum performance.
                  Collaborate with designers and backend teams to integrate features seamlessly. <br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience with React development or relevant internships.
                  Strong understanding of JavaScript, Redux, and RESTful APIs.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>UI/UX Designer</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Design intuitive user interfaces and create seamless user experiences.
                  Conduct user research and create wireframes, prototypes, and mockups.
                  Collaborate with developers to implement designs effectively. <br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in UI/UX design or relevant internships.
                  Proficiency in design tools like Figma, Adobe XD, or Sketch.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>Graphic Designer</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Design marketing materials, logos, brochures, and visual assets.
                  Work closely with teams to bring creative ideas to life.
                  Ensure brand consistency across all designs. <br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in graphic design or relevant internships.
                  Proficiency in tools like Adobe Illustrator, Photoshop, and CorelDRAW.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>React Native Developer</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Develop cross-platform mobile applications using React Native.
                  Integrate APIs and third-party libraries for enhanced functionality.
                  Debug and optimize apps for smooth performance.<br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience with React Native or relevant internships.
                  Knowledge of mobile app architecture and deployment.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>PHP Developer</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Develop and maintain dynamic web applications using PHP.
                  Integrate databases and manage server-side scripting.
                  Debug and enhance existing codebases.<br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in PHP development or relevant internships.
                  Familiarity with frameworks like Laravel or CodeIgniter.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>Project Manager</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Plan, execute, and oversee projects to ensure timely delivery.
                  Collaborate with teams to allocate resources effectively.
                  Manage project timelines, budgets, and client communications. <br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in project management or relevant internships.
                  Strong organizational and leadership skills
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>Social Media Post Designer</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Create visually appealing and engaging social media graphics.
                  Work with marketing teams to develop brand-aligned content.
                  Stay updated on trends to keep designs fresh and relevant. <br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in graphic design or relevant internships.
                  Proficiency in design tools like Canva or Adobe Photoshop.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>Social Media Handler</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br />
                  Manage and grow social media accounts across platforms.
                  Develop and schedule content strategies to boost engagement.
                  Monitor analytics to refine campaigns.<br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in social media management or relevant internships.
                  Familiarity with tools like Hootsuite or Buffer.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>Digital Marketing Specialist</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br /> 
                  Plan and execute online marketing campaigns.
                  Optimize website and content for SEO and performance.
                  Analyze campaign results and recommend improvements.<br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in digital marketing or relevant internships.
                  Knowledge of SEO, SEM, and Google Analytics.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-1">
            <div className="card bg-transparent border-light h-100">
              <div className="card-head">
                <h4 className="text-info mb-0 pt-2 mx-3">
                  <strong>Meta and Google Ads Runner</strong>
                </h4>
              </div>
              <div className="card-body"> 
                <p className="text-light mb-0">
                  <strong className="text-info">Role:</strong> <br /> 
                  Create and manage ad campaigns on Meta (Facebook/Instagram) and Google.
                  Monitor ad performance and optimize for ROI.
                  Research target audiences and refine strategies. <br />
                  <strong className="text-info">Eligibility:</strong>  <br />
                  Experience in ad management or relevant internships.
                  Proficiency in Meta Ads Manager and Google Ads platforms.
                </p>
              </div>
              <div className="card-footer">   
                <button className="btn btn-info w-100" type="button" onClick={() => {window.scrollTo({ top: careerFormDivRef.current!.offsetTop - 120, behavior: "smooth" });}}>Apply</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-3 justify-content-center align-items-center " ref={careerFormDivRef}>
          <div className="col-md-6 d-flex justify-content-center">
            <div className="card card-body border-0 bg-blue min-card">
              <form
                ref={formRef}
                id="form"
                //  onSubmit={handleSubmit}
                action="https://vyomscode.com/contactform.php"
                method="post"
                onSubmit={(e) => {
                  const textarea = document.querySelector<HTMLTextAreaElement>('textarea[name="msg"]');
                  if (textarea) {
                    const additionalText = "  ||  Career Query";
                    textarea.value += additionalText; // Appending predefined text to the user's message
                  }              
                }}
              >
                <div className="row mt-3">
                  <div className="col-12">
                    <p className="text-light mb-1">
                       <b>Currently, No Open Positions Available. Please fill out the form below with your details, 
                        and we'll reach out to you when a suitable opportunity arises.</b>
                    </p>      
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="name"
                      name="name"
                      className="form-control bg-blue-light"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="email"
                      name="email"
                      className="form-control bg-blue-light"
                      placeholder="Your Email Address"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="number"
                      name="number"
                      className="form-control bg-blue-light"
                      placeholder="Your Mobile Number"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <textarea
                      name="msg"
                      id=""
                      cols={35}
                      className="form-control bg-blue-light"
                      rows={5}
                      placeholder="Tell us about yourself and include your LinkedIn profile link so we can view your work and learn more about you"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12 d-flex justify-content-cneter">
                    <button
                      id="submit"
                      type="submit"
                      className="btn btn-info w-100"
                    >
                      Submit
                    </button>
                    {/* <button
                      id="submit"
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-warning w-100"
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Careerpage;
