import React, { useState } from "react"; 

function FAQs() {
  // State to track which card is open 
  const [activeCard, setActiveCard] = useState<number | null>(null);


  // Function to handle card toggle
  const toggleCard  = (index: number) => { 
    setActiveCard(activeCard === index ? null : index);
  }; 

  const faqData = [
    {
      question: "1. What services does Vyomscode offer?",
      answer: (
        <>
          Vyomscode specializes in: <br />
          <strong>• Website Development</strong> <br />
          <strong>• Application Development</strong> <br />
          <strong>• Graphic Designing</strong> <br />
          <strong>• Digital Marketing</strong> <br />
          <strong>• Social Media Management</strong> <br />
          <strong>• Meta and Google Ad Campaigns</strong> <br />
          We also offer tailored solutions based on your project requirements to ensure the best results.
        </>
      ),
    },
    {
      question: "2. What is Vyomscode's workflow for client projects?",
      answer: (
        <>
          Our workflow ensures transparency and efficiency: <br />
          <strong>• Requirement Gathering:</strong> Understanding your goals and project requirements. <br />
          <strong>• Planning:</strong> Creating a detailed project roadmap. <br />
          <strong>• Execution:</strong> Regular updates during development. <br />
          <strong>• Review and Testing:</strong> Ensuring quality through testing. <br />
          <strong>• Delivery and Support:</strong> Delivering the final product with support.
        </>
      ),
    }, 
    {
      question: "3. How do I communicate with Vyomscode during the project?",
      answer: (
        <>
          We believe in open communication and provide multiple channels for updates and queries:<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Email or WhatsApp (for ongoing updates)
          </strong>
          <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Scheduled video or phone calls for detailed discussions
          </strong>
          <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Our team ensures prompt responses to all your queries.
          </strong>
          <br />
        </>
      ),
    },
    {
      question: "4. Can Vyomscode handle both small and large-scale projects?",
      answer: (
        <>
          Yes, Vyomscode is equipped to handle projects of all sizes. Whether you need a simple website, a full-stack application, or an extensive digital marketing campaign, we have the expertise and resources to deliver high-quality results tailored to your needs.
        </>
      ),
    },
    {
      question: "5. Which hosting platforms does Vyomscode work with?",
      answer: (
        <>
          At Vyomscode, we work with several reliable hosting platforms to ensure your project is scalable, secure, and performs well. Our team is experienced in working with:<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Google Cloud
          </strong>
          <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Hostinger
          </strong>
          <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> cPanel
          </strong>
          <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> AWS (Amazon Web Services)
          </strong>
          <br />
          We choose the best hosting solution based on your project needs to ensure optimal performance and reliability.
        </>
      ),
    },
    {
      question: "6. Do you offer CRM solutions for businesses?",
      answer: (
        <>
          Yes, Vyomscode provides CRM solutions that help businesses manage customer relationships effectively. We offer:<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Custom CRM development tailored to your business needs.
          </strong>
          <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Admin and User Role Management:
          </strong>{" "}
          Manage permissions for admins, subadmins, and users to streamline workflows. <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Integration with other platforms:
          </strong>{" "}
          Seamlessly integrate your CRM with tools like email marketing, customer service, and more. <br />
          Our CRM solutions ensure better customer engagement, streamlined processes, and enhanced business growth.
        </>
      ),
    },
    {
      question: "7. Can Vyomscode develop Admin and User/Subadmin panel systems?",
      answer: (
        <>
          Absolutely! We specialize in creating Admin and User/Subadmin panel systems to manage your business operations. Key features include:<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Customizable dashboards for easy monitoring and management.
          </strong>
          <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Role-based access control:
          </strong>{" "}
          Admins can assign different permissions to users and subadmins.<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> User activity tracking and reporting:
          </strong>{" "}
          Gain insights into user behavior and actions.<br />
          This helps businesses maintain control and enhance operational efficiency.
        </>
      ),
    },
    {
      question: "8. Do you develop applications for mobile platforms?",
      answer: (
        <>
          Yes, Vyomscode offers mobile application development for both Android and iOS platforms. We build:<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Native apps:
          </strong>{" "}
          Tailored for optimal performance on each platform.<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Hybrid apps:
          </strong>{" "}
          Cross-platform apps built using technologies like React Native.<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Backend integration:
          </strong>{" "}
          Seamlessly connect your app with backend systems for real-time data synchronization.<br />
          We work with you to create applications that meet your business requirements and provide excellent user experiences.
        </>
      ),
    },
    {
      question: "9. How do you publish apps on the Play Store and App Store?",
      answer: (
        <>
          Vyomscode manages the entire app publishing process to ensure a smooth launch on major app stores:<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Google Play Store:
          </strong>{" "}
          We handle the submission, store listing, and optimization for Android apps.<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Apple App Store:
          </strong>{" "}
          We guide you through the submission process, including app review and listing for iOS apps.<br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> App Optimization:
          </strong>{" "}
          Our team ensures your app is optimized for store visibility and meets the store's guidelines.<br />
          We'll guide you through the app publishing process to ensure maximum reach and user engagement.
        </>
      ),
    },
    {
      question: "10. Can Vyomscode build multiplatform apps?",
      answer: (
        <>
          Yes! We specialize in building multiplatform applications using React Native, Flutter, and other frameworks. This allows you to: <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Develop a single codebase that works on both Android and iOS.
          </strong>
          <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Save time and development costs compared to building separate apps for each platform.
          </strong>
          <br />
          <strong>
            <i className="bi bi-circle-fill" style={{ fontSize: 8 }}></i> Reach a broader audience with apps available on multiple devices and platforms.
          </strong>
          <br />
          Our team ensures your multiplatform app works seamlessly across all devices, providing a consistent user experience.
        </>
      ),
    },
  ];
  

  return (
    <section id="contact"> 
      <div className="container pt-5 text-white pb-md-5">
        <h2 className="color-blue text-center mb-3">
          <strong>Frequently Asked Questions in VyomsCode</strong>
        </h2>

        <div className="row">
 
          {faqData.map((faq, index) => (
            <div className="col-md-12 my-1" key={index}>
              <div className="card bg-transparent border-secondary h-100">
                <div
                  className="card-head"
                  onClick={() => toggleCard(index)}
                  style={{ cursor: "pointer" }}
                >
                  <h5 className="text-light mb-0 py-2 mx-3">
                    <strong>{faq.question}</strong>
                  </h5>
                </div>
                <div
                  className="card-body"
                  style={{
                    display: activeCard === index ? "block" : "none",
                    transition: "all 0.3s ease",
                  }}
                >
                  <p className="text-light mb-0 lh-lg">{faq.answer}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQs;

